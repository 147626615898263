import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import BigNumber from 'bignumber.js'
import CardValue from 'components/CardValue'
import ConnectWallet from 'components2/Common/Popup/ConnectWallet'
import GlobalModal from 'components2/static/GlobalModal'
import tokens from 'config/constants/tokens'
import { useActiveWeb3React } from 'hooks'
import { useApproveCallbackCustom } from 'hooks/useApproveCallback'
import useStake from 'hooks/useStaking'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import { useUserDataSelector } from 'state/hooks'
import { fetchPoolWithId, fetchUserTokenData, setPendingRewardToZero } from 'state/staking'
import { PoolInfo, UserPoolData } from 'state/types'
import { getAddress, getStakingAddress } from 'utils/addressHelpers'
import { ButtonStyled } from './styled'

interface Pool extends PoolInfo, UserPoolData {}
interface Props {
  pool: Pool
}

export default function PoolInfoCard({ pool }: Props) {
  const [showAnimal, setShowAnimal] = useState<boolean>(false)
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [isStaking, setIsStaking] = useState<boolean>(false)
  const [isClaiming, setIsClaiming] = useState<boolean>(false)
  const [isWithdrawing, setWithdrawing] = useState<boolean>(false)
  const [blockNumber, SetBlockNumber] = useState<number>(0)
  const [value, setValue] = useState<number>()
  const dispatch = useAppDispatch()
  const { account, library } = useActiveWeb3React()
  const { userTokenData } = useUserDataSelector()
  const { onStake, onClaim, onWithdraw } = useStake(pool.poolId)
  const [onApproveAngel] = useApproveCallbackCustom(
    getAddress(pool.token === 0 ? tokens.angel.address : tokens.angelBnbLp.address),
    getStakingAddress(),
  )
  const unlockTime = new Date((pool?.firstTimeDeposit + parseInt(pool?.lockingTime)) * 1000)

  const bigUserAmountStaked = new BigNumber(pool?.amountStaked || 0).div(1e18)
  const bigAmount1Staked = new BigNumber(pool?.apyOnAmount?.[1]?.amount).div(1e18)
  const bigAmount2Staked = new BigNumber(pool?.apyOnAmount?.[2]?.amount).div(1e18)
  const bigAmount3Staked = new BigNumber(pool.maxStakingAmountPerUser).div(1e18)

  const firstStakingPercent = bigUserAmountStaked.lt(bigAmount1Staked)
    ? bigUserAmountStaked.div(bigAmount1Staked).times(33.33).toNumber()
    : 33.33

  const secondStakingPercent =
    firstStakingPercent === 33.33
      ? bigUserAmountStaked
          .minus(bigAmount1Staked)
          .div(bigAmount2Staked.minus(bigAmount1Staked))
          .times(33.33)
          .toNumber()
      : 0

  const thirdStakingPercent =
    secondStakingPercent === 33.33
      ? bigUserAmountStaked
          .minus(bigAmount2Staked)
          .div(bigAmount3Staked.minus(bigAmount2Staked))
          .times(33.33)
          .toNumber()
      : 0

  useEffect(() => {
    const fetchBlockNumber = async () => {
      SetBlockNumber(await library.getBlockNumber())
    }
    fetchBlockNumber()
  }, [])
  const handleShow = () => {
    setShowAnimal(!showAnimal)
  }

  const handleApprove = async () => {
    setIsApproving(true)
    try {
      const tx = await onApproveAngel()
      await tx?.wait()
      dispatch(fetchUserTokenData(account))
      setIsApproving(false)
    } catch (error) {
      setIsApproving(false)
    }
  }

  const handleStake = async () => {
    try {
      setIsStaking(true)
      const tx = await onStake(value)

      const result = await tx.wait()

      setIsStaking(false)

      dispatch(fetchPoolWithId({ account, poolId: pool.poolId }))
      dispatch(fetchUserTokenData(account))
    } catch (error: any) {
      setIsStaking(false)
    }
  }

  const handleClaim = async () => {
    try {
      setIsClaiming(true)
      const tx = await onClaim()

      const result = await tx.wait()

      setIsClaiming(false)

      dispatch(fetchUserTokenData(account))
      dispatch(setPendingRewardToZero(pool.poolId))
    } catch (error: any) {
      setIsStaking(false)
    }
  }

  const handleWithdraw = async () => {
    try {
      setWithdrawing(true)
      const tx = await onWithdraw()

      const result = await tx.wait()

      setWithdrawing(false)

      dispatch(fetchUserTokenData(account))
      dispatch(setPendingRewardToZero(pool.poolId))
      dispatch(fetchPoolWithId({ account, poolId: pool.poolId }))
    } catch (error: any) {
      setWithdrawing(false)
    }
  }

  const handleMax = () => {
    setValue(
      new BigNumber(pool.token === 0 ? userTokenData.angelBalance : userTokenData.lpBalance).div(1e18).toNumber(),
    )
  }

  const handleConnectWallet = () => {
    return GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
  }
  if (pool.poolId === '3') {
    // console.log(
    //   '(pool?.firstTimeDeposit + parseInt(pool?.lockingTime)) * 1000',
    //   (pool?.firstTimeDeposit + parseInt(pool?.lockingTime)) * 1000,
    // )
    // console.log('Date.now() < remain.getTime()', Date.now() < remain.getTime())
    // console.log('data.now()', Date.now())
    // console.log('remain.getTime()', remain.getTime())
    // console.log('blockNumber', blockNumber)
    // console.log('pool.endBlock', pool.endBlock)
    // console.log('lastBlockNumber < pool.endBlock', blockNumber < pool.endBlock)
  }

  return (
    // <div className="box__widget__item filled finished">
    <div
      className={`box__widget__item  ${
        blockNumber > pool.endBlock ? 'finished' : pool.totalStaked === pool.maxStakingAmount && 'filled'
      }`}
    >
      <h4>Daily Vesting</h4>
      <p className="month">{pool.title} Vault</p>

      <div className="total">
        <span>Total raise</span>
        <p>
          <img src={`./images/icon/${pool.token === 0 ? 'angels-token' : 'icon-bnb-agt'}.png`} alt="icon angels" />
          <CardValue
            value={parseFloat(new BigNumber(pool.totalStaked).div(1e18).toFixed(0)) || 0}
            decimals={0}
          ></CardValue>
          /
          <CardValue
            value={parseFloat(new BigNumber(pool.maxStakingAmount).div(1e18).toFixed(0)) || 0}
            decimals={0}
          ></CardValue>
          {pool.token === 0 ? 'Angel' : 'LP'}
        </p>
      </div>

      <div className="box__progess">
        <div className="box__progess__percent">
          <span>APY</span>
          <span>{pool.token === 0 ? pool?.apyOnAmount?.[0]?.apy : parseInt(pool?.apyOnAmount?.[0]?.apy) / 500}%</span>
          <span>{pool.token === 0 ? pool?.apyOnAmount?.[1]?.apy : parseInt(pool?.apyOnAmount?.[1]?.apy) / 500}%</span>
          <span>{pool.token === 0 ? pool?.apyOnAmount?.[2]?.apy : parseInt(pool?.apyOnAmount?.[2]?.apy) / 500}%</span>
        </div>

        <div className="box__progess__line">
          <div
            className="progress"
            style={{ width: `${firstStakingPercent + secondStakingPercent + thirdStakingPercent}%` }}
          ></div>
          <div className="active"></div>
          <div className={`circle ${firstStakingPercent >= 33.33 && 'blue'}`}></div>
          <div className={`circle ${secondStakingPercent >= 33.33 && 'blue'}`}></div>
          <div className=""></div>
        </div>

        <div className="box__progess__musty">
          <span>
            <CardValue
              value={new BigNumber(pool?.apyOnAmount?.[0]?.amount).div(1e18).toNumber() || 0}
              decimals={0}
            ></CardValue>
          </span>
          <span>
            <CardValue
              value={new BigNumber(pool?.apyOnAmount?.[1]?.amount).div(1e18).toNumber() || 0}
              decimals={0}
            ></CardValue>
          </span>
          <span>
            <CardValue
              value={new BigNumber(pool?.apyOnAmount?.[2]?.amount).div(1e18).toNumber() || 0}
              decimals={0}
            ></CardValue>
          </span>
          <span>
            <CardValue
              value={new BigNumber(pool?.maxStakingAmountPerUser).div(1e18).toNumber() || 0}
              decimals={0}
            ></CardValue>
          </span>
        </div>
      </div>
      {account ? (
        <>
          {(pool.token === 0 ? userTokenData.angelAllowance === '0' : userTokenData.lpAllowance === '0') ? (
            <div className="connect-wallet">
              <ButtonStyled disabled={isApproving} onClick={handleApprove}>
                Enable
                <img
                  src={`./images/icon/${pool.token === 0 ? 'angels-token' : 'icon-bnb-agt'}.png`}
                  alt="icon angels"
                />
              </ButtonStyled>
            </div>
          ) : (
            <>
              {Date.now() < unlockTime.getTime() || pool.amountStaked === '0' ? (
                <>
                  <div className="rounded">
                    <button type="submit" onClick={handleMax}>
                      Max
                    </button>
                    <input
                      type="number"
                      value={value}
                      id="fname"
                      name="fname"
                      // pattern="^[0-9]*[.,]?[0-9]*$"
                      placeholder="0.000"
                      min="0"
                      maxLength={79}
                      onChange={(e) => setValue(parseFloat(e.currentTarget.value))}
                    />
                  </div>
                  <ButtonStyled disabled={isStaking} onClick={handleStake}>
                    Stake
                  </ButtonStyled>
                </>
              ) : (
                <>
                  <div className="connect-wallet">
                    <ButtonStyled disabled={isWithdrawing} onClick={handleWithdraw}>
                      withdraw
                    </ButtonStyled>
                  </div>
                </>
              )}
            </>
          )}

          <p className="totalstake" onClick={handleShow}>
            Total Staked:
            <span className="totalstake__number">
              <CardValue value={new BigNumber(pool.amountStaked).div(1e18).toNumber() || 0} decimals={2}></CardValue>
              {pool.token === 0 ? 'Angel' : 'LP'}
            </span>
            {showAnimal ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
          </p>

          {showAnimal && (
            <div className="looked__until">
              <span className="looked__until__title">
                Locked until{' '}
                {unlockTime.getTime() === parseInt(pool.lockingTime) * 1000
                  ? '__/__/____'
                  : unlockTime.toLocaleDateString('en-GB')}
              </span>

              <div className="two__claim">
                <div className="two__claim__left">
                  <span>Ready to claim</span>
                  <p>
                    <img src="./images/icon/angels-token.png" alt="icon angels" />
                    <CardValue
                      value={new BigNumber(pool.pendingReward).div(1e18).toNumber() || 0}
                      decimals={4}
                    ></CardValue>
                  </p>
                </div>

                <div className="two__claim__right">
                  <span>Estimate profit</span>
                  <p>
                    <img src="./images/icon/angels-token.png" alt="icon angels" />
                    <CardValue
                      value={new BigNumber(pool.expectedInterestEndStaking).div(1e18).toNumber() || 0}
                      decimals={2}
                    ></CardValue>
                  </p>
                </div>
              </div>
              {(Date.now() < unlockTime.getTime() || pool.amountStaked === '0') && (
                <ButtonStyled disabled={pool.pendingReward === '0' || isClaiming} onClick={handleClaim}>
                  Claim
                </ButtonStyled>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="connect-wallet">
          <ButtonStyled onClick={handleConnectWallet}>Connect wallet</ButtonStyled>
        </div>
      )}
    </div>
  )
}
