import { useActiveWeb3React } from 'hooks'
import useAuth from 'hooks/useAuth'
import React from 'react'
import styled from 'styled-components'
import MusicButton from '../MusicButton'
Connect.propTypes = {}

function Connect({ Wallet }) {
  const { logout } = useAuth()
  const { account } = useActiveWeb3React()
  const trimAccount = account ? `${account.slice(0, 4)}...${account.slice(38)}` : '0x00...0000' // account length: 42

  return (
    <ConnectStyled>
      {!account ? (
        <div className="Connect__connect active" onClick={Wallet}>
          <p>Connect Wallet</p>
          <img src="./images/icon-connect.png" alt=""/>
        </div>
      ) : (
        <div className="Connect__connect" onClick={logout}>
          <p>{trimAccount}</p>
        </div>
      )}
    </ConnectStyled>
  )
}

export const ConnectStyled = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  background-color: #4791f2;
  padding: 5px 0;
  border-radius: 18px;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 6px;
    margin: 0 auto;
    max-width: 60px;
  }

  p {
    margin-bottom: 0;

    @media (max-width: 767px) {
      font-size: 0.7em;
    }
  }

  img {
    padding-bottom: 5px;
    transition: ease all 0.5s;

    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }

  .Connect__connect {
    text-align: center;
    cursor: pointer;

    &.active {
      p {
        display: block;

        @media (max-width: 767px) {
          display: none;
        }
      }

      img {
        display: none;

        @media (max-width: 767px) {
          display: block;
        }
      }
    }
  }
`
export default Connect
