import {createAsyncThunk} from '@reduxjs/toolkit'
import {BigNumber, ethers, Overrides} from 'ethers'
import {groupBy, map, values as _values, merge, keyBy} from 'lodash'
import marketplaceApi from 'config/abi/AngelCreedMarketPlace.json'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import characterAbi from 'config/abi/character.json'
import {getMarketplaceAddress, getFakeCharacterAddress, getCharacterAddress} from 'utils/addressHelpers'
import {getMarketplaceContract} from 'utils/contractHelpers'
import {_getCharactersByIds, _getListingDataByIdentifiers} from 'state/marketplace/utils/contractInteraction'
import {fetchingMarket, updateMarketPage} from './actions'
import {State} from '../../types'
import {Listing} from '../types/type'

export const fetchSellerFee = createAsyncThunk('marketplace/fetchSellerFee', async (_) => {
  const marketplaceContract = getMarketplaceContract()
  try {
    return (await marketplaceContract.sellerFee()).toString()
  } catch (e) {
    console.log(e)
  }
})

export const fetchBuyerFee = createAsyncThunk('marketplace/fetchBuyerFee', async (_) => {
  const marketplaceContract = getMarketplaceContract()
  try {
    return (await marketplaceContract.buyerFee()).toString()
  } catch (e) {
    console.log(e)
  }
})

const fetchingMarketDataByPage = async (counterPage, limit) => {
  const marketplaceContract = getMarketplaceContract()

  const tokenListed = await marketplaceContract?.getListedTokenIdByPage(
    BigNumber.from(counterPage),
    BigNumber.from(limit),
  )
  const listingCharacters = await _getListingDataByIdentifiers(
    getMarketplaceAddress(),
    marketplaceApi,
    'getListingByIdentifier',
    tokenListed,
  )
  let groupedListing: any = {
    [getCharacterAddress()]: [],
    [getFakeCharacterAddress()]: [],
  }

  groupedListing = {...groupedListing, ...(groupBy(listingCharacters, 'listingNft') as any)}
  const charIds = map(groupedListing[getCharacterAddress()], 'id')
  const fakeCharIds = map(groupedListing[getFakeCharacterAddress()], 'id')

  return {
    tokenListed,
    charIds,
    fakeCharIds,
    groupedListing,
  }
}

export const fetchListingCharacters = createAsyncThunk(
  'marketplace/fetchListingCharacters',
  async (_, {getState, dispatch}) => {
    try {
      let characters = []
      let characterIds = []
      let fakeCharacterIds = []
      let groupedListingCharacters = {
        [getCharacterAddress()]: [],
        [getFakeCharacterAddress()]: [],
      }

      const {
        marketplace: {
          pagintion: {limit, marketPage},
        },
      } = getState() as State

      let counterPage = marketPage
      let {tokenListed, charIds, fakeCharIds, groupedListing}: any = await fetchingMarketDataByPage(counterPage, limit)

      characterIds = [...characterIds, ...charIds]
      fakeCharacterIds = [...fakeCharacterIds, ...fakeCharIds]
      groupedListingCharacters = {
        [getCharacterAddress()]: [
          ...groupedListingCharacters[getCharacterAddress()],
          ...groupedListing[getCharacterAddress()],
        ],
        [getFakeCharacterAddress()]: [
          ...groupedListingCharacters[getFakeCharacterAddress()],
          ...groupedListing[getFakeCharacterAddress()],
        ],
      }

      let validDataLength = characterIds.length + fakeCharacterIds.length

      while (tokenListed.length >= limit && validDataLength < limit) {
        const result = await fetchingMarketDataByPage(++counterPage, limit)
        tokenListed = result.tokenListed
        charIds = result.charIds
        fakeCharIds = result.fakeCharIds
        groupedListing = result.groupedListing

        characterIds = [...characterIds, ...charIds]
        fakeCharacterIds = [...fakeCharacterIds, ...fakeCharIds]
        groupedListingCharacters = {
          [getCharacterAddress()]: [
            ...groupedListingCharacters[getCharacterAddress()],
            ...groupedListing[getCharacterAddress()],
          ],
          [getFakeCharacterAddress()]: [
            ...groupedListingCharacters[getFakeCharacterAddress()],
            ...groupedListing[getFakeCharacterAddress()],
          ],
        }

        validDataLength = characterIds.length + fakeCharacterIds.length
      }

      await Promise.all([
        _getCharactersByIds(getCharacterAddress(), characterAbi, 'getHero', characterIds, true),
        _getCharactersByIds(getFakeCharacterAddress(), fakeCharacterAbi, 'getHero', fakeCharacterIds, false),
      ]).then((values) => {
        characters = [
          ..._values(merge(keyBy(values[0], '_id'), keyBy(groupedListingCharacters[getCharacterAddress()], '_id'))),
          ..._values(merge(keyBy(values[1], '_id'), keyBy(groupedListingCharacters[getFakeCharacterAddress()], '_id'))),
        ]
      })

      dispatch(fetchingMarket(false))
      dispatch(updateMarketPage(counterPage))

      return characters
    } catch (e) {
      console.log('Error', e)
      return []
    }
  },
)

export const addListing = createAsyncThunk(
  'marketplace/addListing',
  async ({account, id, price, paymentToken, listingNft, options}: Listing, {getState, dispatch}) => {
    try {
      const {
        marketplace: {
          fee: {sellerFee},
        },
      } = getState() as State

      if (paymentToken === ethers.constants.AddressZero) {
        const feeRequired = ethers.utils
          .parseEther(price.toString())
          .mul(BigNumber.from(sellerFee))
          .div(BigNumber.from(100))

        const overrides = {
          value: feeRequired,
        }

        const tx = await options.contract.addListing(
          BigNumber.from(id),
          ethers.utils.parseEther(price.toString()),
          paymentToken,
          listingNft,
          overrides as Overrides,
        )
        await tx.wait()

        options.callback({success: true, tx: tx.hash, message: 'Add Listing success'})
        if (options.others.length > 0) {
          options.others.map((functionCall) => functionCall())
        }
      } else {
        const tx = await options.contract.addListing(
          BigNumber.from(id),
          ethers.utils.parseEther(price.toString()),
          paymentToken,
          listingNft,
        )
        await tx.wait()

        options.callback({success: true, tx: tx.hash, message: 'Add Listing success'})
        if (options.others.length > 0) {
          options.others.map((functionCall) => functionCall())
        }
      }

      const character = (getState() as State).marketplace.inventoryCharacters[`${listingNft}_${id}`]
      return {
        ...character,
        seller: account,
        paymentToken,
        listingNft,
        id,
        price,
      }
    } catch (e) {
      options.callback({success: false, tx: null, message: 'Add Listing fail'})
      if (options.others.length > 0) {
        options.others.map((functionCall) => functionCall())
      }
      return {}
    }
  },
)

export const cancelListing = createAsyncThunk(
  'marketplace/cancelListing',
  async ({listingNft, id, options}: Listing, {getState, dispatch}) => {
    try {
      const tx = await options.contract.cancelListing(listingNft, BigNumber.from(id))
      await tx.wait()

      options.callback({success: true, tx: tx.hash, message: 'Cancel Listing success'})
      if (options.others.length > 0) {
        options.others.map((functionCall) => functionCall())
      }

      const character = (getState() as State).marketplace.inventoryCharacters[`${listingNft}_${id}`]
      return {
        ...character,
        seller: '',
        paymentToken: '',
        listingNft: '',
        id: '',
        price: '',
      }
    } catch (e) {
      options.callback({success: false, tx: null, message: 'Cancel Listing fail'})
      if (options.others.length > 0) {
        options.others.map((functionCall) => functionCall())
      }
      return {}
    }
  },
)

export const purchaseListing = createAsyncThunk(
  'marketplace/purchaseListing',
  async ({listingNft, id, price, paymentToken, options}: Listing, {getState, dispatch}) => {
    try {
      const {
        marketplace: {
          fee: {buyerFee},
        },
      } = getState() as State

      if (paymentToken === ethers.constants.AddressZero) {
        const feeRequired = ethers.utils
          .parseEther(price.toString())
          .mul(BigNumber.from(buyerFee).add(BigNumber.from(100)))
          .div(BigNumber.from(100))

        const overrides = {
          value: feeRequired,
        }

        const tx = await options.contract.purchaseListing(listingNft, BigNumber.from(id), overrides as Overrides)
        await tx.wait()

        options.callback({success: true, tx: tx.hash, message: 'Buy success'})
        if (options.others.length > 0) {
          options.others.map((functionCall) => functionCall())
        }
      } else {
        const tx = await options.contract.purchaseListing(listingNft, BigNumber.from(id))
        await tx.wait()

        options.callback({success: true, tx: tx.hash, message: 'Buy success'})
        if (options.others.length > 0) {
          options.others.map((functionCall) => functionCall())
        }
      }

      const character = (getState() as State).marketplace.listings[`${listingNft}_${id}`]
      return {
        ...character,
        seller: '',
        paymentToken: '',
        listingNft: '',
        id: '',
        price: '',
      }
    } catch (e: any) {
      const textError = e?.data?.message ?? e.toString();
      if (textError.includes('Not enought coin')){
        options.callback({success: false, tx: null, message: 'Insufficient funds'})
      }

      options.callback({success: false, tx: null, message: 'Insufficient funds'})
      if (e.data.message.includes('insufficient funds for transfer')){
        options.callback({success: false, tx: null, message: 'Insufficient funds'})
      } 
      else if (options.others.length > 0) {
        options.others.map((functionCall) => functionCall())
      }
      return {}
    }
  },
)
