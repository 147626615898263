import { StakingConfig } from './types'

export const stakingConfig: StakingConfig[] = [
  { title: '1-Month', poolId: '1', lockingTime: '2592000', token: 0 }, // 1 month
  { title: '3-Month', poolId: '2', lockingTime: '7776000', token: 0 }, // 3 months
  { title: '6-Month', poolId: '3', lockingTime: '15552000', token: 0 }, // 6 months
  { title: '1-Month', poolId: '4', lockingTime: '2592000', token: 1 },
  { title: '3-Month', poolId: '5', lockingTime: '7776000', token: 1 },
  { title: '6-Month', poolId: '6', lockingTime: '15552000', token: 1 },
]
