import { useActiveWeb3React } from 'hooks'
import React, { useEffect } from 'react'
import { useAppDispatch } from 'state'
import { usePoolSelector, useUserDataSelector } from 'state/hooks'
import { fetchPendingRewardAllPool } from 'state/staking'
import useRefresh from 'hooks/useRefresh'
import AngelsPrice from './Component/AngelsPrice'
import AngelsStaking from './Component/AngelsStaking'
import MenuHead from './Component/header'
import LPStaking from './Component/LPStaking'
import { WidgetHome } from './styled'

export default function HomePage({ setCurrentPage }) {
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()
  const { userPoolData } = useUserDataSelector()
  const pools = usePoolSelector()
  const { slowRefresh } = useRefresh()

  const mergePool = pools.map((pool, index) => {
    return { ...pool, ...userPoolData[index] }
  })
  const tokenPools = mergePool.slice(0, 3)
  const lpPools = mergePool.slice(3)

  useEffect(() => {
    if (account) dispatch(fetchPendingRewardAllPool(account))
  }, [slowRefresh])

  return (
    <>
      <WidgetHome>
        <MenuHead />
        <AngelsPrice tokenPools={tokenPools} lpPools={lpPools} />
        <AngelsStaking pools={tokenPools} />
        <LPStaking pools={lpPools} />
      </WidgetHome>
    </>
  )
}
