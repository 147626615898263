import React from 'react'
import styled from 'styled-components'
import GlobalModal from 'components2/static/GlobalModal'
import PopupConnect from 'components2/Common/Popup/PopupConnect'
import {Button, connectorLocalStorageKey, ConnectorNames} from '@diviner-protocol/uikit'
import {connectors} from 'config/constants/wallets'
import useAuth from 'hooks/useAuth'

function ConnectWallet({onClose}) {
  const {login, logout} = useAuth()

  const handleLogin = (connectorId: ConnectorNames) => {
    login(connectorId)
    window.localStorage.setItem(connectorLocalStorageKey, connectorId)
    onClose()
  }
  return (
    <PopupConnect onClick={onClose} title="Connect to a wallet">
      <ConnectWalletStyled>
        <div className="connectWallet">
          {connectors.map((config) => (
            <div className="connectWallet__content" onClick={() => handleLogin(config.connectorId)}>
              <img src={`./images/connect/${config.icon}`} alt={config.title} />
              <p>{config.title}</p>
            </div>
          ))}
        </div>
        <div className="connectWallet__learn">
          <img src="./images/connect/learn-how.png" alt="Learn.png" />
          <p>Learn how to connect</p>
        </div>
      </ConnectWalletStyled>
    </PopupConnect>
  )
}

export const ConnectWalletStyled = styled.div`
  p {
    color: #fff;
    font-weight: 400px;
  }

  .connectWallet {
    padding: 6rem 2rem 0;
    display: grid;
    text-align: center;
    justify-content: center;
    align-self: center;
    grid-gap: 30px 60px;
    grid-template-columns: auto auto;
  }


    .connectWallet__content {
      transition: ease all 0.5s;
      cursor: pointer;
      padding-bottom: 1rem;

      &:hover {
        transform: scale(1.1);
      }

      img {
        margin-bottom: 12px;
      }

      p {
        font-size: 17px;
        color: #000;
      }
    }
  }

  .connectWallet__learn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: ease all 0.5s;
    &:hover {
      opacity: 0.8;
    }
    p {
      padding-left: 10px;
      margin: 0;
      font-size: 18px;
    }
  }
`
export default ConnectWallet
