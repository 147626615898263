import React from 'react'
import styled from 'styled-components'
import {isMobile} from 'react-device-detect'

const LoadingFullScreen = (props) => {
  return (
    <LoadingMain className="loader-container" {...props}>
      <div className="box-video">
        <img src="./images/loading.gif" alt=""/>
      </div>
    </LoadingMain>
  )
}

const LoadingMain = styled.div`
  img {
    position: absolute;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;

    @media (max-width: 767px) {
      width: 200px;
    }
  }
`

export default LoadingFullScreen
