import {ConfigWallets} from 'config/constants/types'
import {ConnectorNames} from '@diviner-protocol/uikit'

export const connectors: ConfigWallets[] = [
  {
    title: 'Metamask',
    icon: 'metamask.png',
    connectorId: ConnectorNames.Injected,
  },
  {
    title: 'TrustWallet',
    icon: 'trust.png',
    connectorId: ConnectorNames.WalletConnect,
  },
  {
    title: 'WalletConnect',
    icon: 'wallet-connect.png',
    connectorId: ConnectorNames.WalletConnect,
  },
  {
    title: 'Binance Chain Wallet',
    icon: 'binance-wallet.png',
    connectorId: ConnectorNames.BSC,
  },
]

// comment nay dung de commit
