import styled from 'styled-components'

export const WidgetMenu = styled.div`
  color: #ffffff;
  background-color: #2d3472;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  .box__main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      padding-bottom: 10px;
    }
  }

  .box__left {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-gap: 20px;
  }

  .box__right {
    display: grid;
    grid-template-columns: auto auto 200px;
    align-items: center;
    grid-gap: 20px;

    @media (max-width: 767px) {
      grid-template-columns: auto auto 60px;
    }
  }

  ul.list__menu {
    list-style: none;
    margin-bottom: 0;

    li {
      display: inline-block;
      padding: 20px;

      a {
        color: #ffffff;
        font-size: 1.7em;
        transition: 1s;

        @media (max-width: 767px) {
          font-size: 1em;
        }

        &:hover {
          color: #4791f2;
          transition: 1s;
        }
      }
    }
  }

  .box__coin {
    position: relative;
    background: #00000050;
    height: 34px;
    display: flex;
    align-items: center;
    border-radius: 50px;

    &__item {
      position: relative;
      z-index: 2;
      display: grid;
      grid-template-columns: 50px 1fr 32px;
      align-items: center;

      @media (max-width: 767px) {
        grid-template-columns: 20px 1fr 15px;
      }

      img {
        width: 32px;
      }

      img.gem-token {
        width: 32px;
      }

      span {
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        margin: 0 20px;

        @media (max-width: 767px) {
          margin: 0 10px 0 20px;
          overflow-x: hidden;
          text-overflow: ellipsis;
          width: 60px;
        }
      }
    }
  }
`

export const WidgetPrice = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px;
  margin: 40px 0 60px;
  padding-bottom: 30px;
  border-bottom: 1px solid #424669;
  margin-top: 140px;

  @media (min-width: 1480px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .item {
    padding: 20px;
    background: #424669;
    border-radius: 12px;
    color: #ffffff;
    text-align: center;

    h3 {
      color: #ffffff;
      font-size: 1.3em;
    }

    p {
      font-size: 1.8em;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
`

export const WidgetStaking = styled.div`
  border-bottom: 1px solid #424669;
  padding-bottom: 60px;
  margin-bottom: 40px;

  .box {
    &__title {
      text-align: center;
      color: #ffffff;
      margin-bottom: 35px;

      h2 {
        font-size: 2.3em;
        font-weight: 600;
        margin-bottom: 0;
        color: #ffffff;
      }

      span {
        font-size: 1.3em;
      }
    }

    &__widget {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 40px;
      color: #ffffff;

      @media (min-width: 1480px) {
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      }
    }

    &__widget__item {
      background: #292b3e;
      border-radius: 12px;
      padding: 20px;
      width: 100%;
      border-radius: 16px;
      position: relative;
      height: fit-content;

      &.finished,
      &.filled  {
        .month {
          position: relative;
          background: linear-gradient(264.32deg, #D8D8D8 6.28%, #A2A2A2 47.81%, #838383 85.22%);

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            width: 62px;
            height: 62px;
          }
        }
      }

      &.finished  {
        .month {
          &:after {
            background: url("../images/icon-finish.png") no-repeat;
            background-size: contain;
          }
        }
      }

      &.filled {
        .month {
          &:after {
            background: url("../images/icon-filled.png") no-repeat;
            background-size: contain;
          }
        }
      }

      h4 {
        color: #ffffff;
        text-align: center;
        font-size: 1.2em;
      }

      .month {
        background: linear-gradient(264.32deg, #2697e9 6.28%, #1d6aff 47.81%, #532af8 85.22%);
        margin-right: -20px;
        margin-left: -20px;
        padding: 15px 20px;
        text-align: center;
        font-size: 2.2em;
        font-weight: 600;
      }

      .total {
        display: flex;
        justify-content: space-between;
        font-size: 1.3em;

        img {
          width: 25px;
          margin-right: 10px;
        }
      }

      .connect-wallet {
        margin-top: 27px;
      }
    }
  }

  .box__progess {
    &__percent {
      display: grid;
      grid-template-columns: 50px 100px 1fr 1fr;
      font-weight: 500;
      
      span {
        font-size: 1.16em;
        text-align: center;

        &:nth-child(2) {
          text-align: left;
        }
      }
    }

    &__line {
      display: flex;
      justify-content: space-between;
      max-width: 100%;
      width: 100%;
      position: relative;

      &:before {
        content: '';
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        left: 0;
        height: 8px;
        width: 100%;
        transform: translateY(-50%);
        z-index: 1;
        border-radius: 16px;
      }

      .progress {
        background-color: #3498db;
        position: absolute;
        top: 50%;
        left: 0;
        height: 8px;
        width: 0%;
        transform: translateY(-50%);
        z-index: 1;
        transition: 0.4s ease;
        border-radius: 16px;

        &:before {
        }
      }

      .circle {
        background-color: #b6b8ba;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid #ffffff;
        transition: 0.4s ease;
        position: relative;
        z-index: 2;

        &.blue {
          border-color: #ffffff;
          background-color: #006fff;
        }
      }
    }

    &__musty {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      font-weight: 500;
      font-size: 1.4;

      span {
        text-align: right;

        &:first-of-type,
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }

  .rounded {
    background-color: #ffffff;
    border-radius: 14px;
    position: relative;
    height: 46px;
    margin-top: 20px;
    margin-bottom: 20px;

    button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      border: none;
      outline: none;
      background: transparent;
      font-size: 1.1em;
      font-weight: 600;
      color: #4791f2;
      font-size: 1.1em;
      font-weight: 600;
      cursor: pointer;
      transition: 1s ease-in-out;

      &:hover {
        color: #023b85;
        transition: 1s ease-in-out;
      }
    }

    input {
      margin-right: 20px;
      background: transparent;
      border: 0;
      outline: 0;
      padding-left: 10px;
      height: 46px;
      color: #000000;
      font-weight: 600;
      font-size: 1.4em;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .totalstake {
    text-align: center;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    color: #ffffff;
    font-size: 1.3em;
    font-weight: 600;

    &:hover {
      color: #4791f2;
      transition: 0.5s ease-in-out;
    }

    span {
      margin-left: 10px;
    }

    &__number {
      padding-left: 10px;
      padding-right: 10px;
    }

    .anticon-double-right {
      transform: rotate(90deg);
    }

    .anticon-double-left {
      transform: rotate(90deg);
    }
  }

  .looked__until {
    text-align: center;

    &__title {
      display: block;
      margin-bottom: 20px;
    }
  }

  .two__claim {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    text-align: center;
    margin-bottom: 20px;

    &__left,
    &__right {
      background: #767783;
      border-radius: 12px;
      padding: 10px;

      span {
        font-size: 1.2em;
      }

      p {
        font-size: 1.5em;
        font-weight: 600;
        margin-bottom: 0;
        text-align: center;
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }
`

export const ButtonStyled = styled.button`
  display: flex;
  text-transform: capitalize;
  background-color: #4791f2;
  font-weight: 500;
  font-size: 1.2em;
  padding: 10px;
  border-radius: 18px;
  min-width: 200px;
  margin: 0 auto 20px auto;
  text-align: center;
  border: none;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  img {
    margin-left: 10px;
    width: 20px;
  }

  &:disabled {
    filter: grayscale(60%);
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed;
    }
  }
`
