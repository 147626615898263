import { GameConfig, GameCharacterConfig, EnemyConfig } from './types'

const game: GameConfig = {
  angelAddresses: {
    56: '0x205f93cd558aac99c4609d0511829194b5405533',
    97: '0x965919bCb51cCE4FeB5aE062f710226037738e00',
  },

  creedAddresses: {
    56: '0x5c2aaadd1fce223baaefb1cf41ce872e9d8b986a',
    97: '0x756dD03Fe43d9e3e50D2ABd7093E572C531916EF',
  },
  busdAddresses: {
    56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    97: '0x6f676559Fd106Bd66d5C812180694232998098d6',
  },
}

export const gameCharacter: GameCharacterConfig[][] = [
  [
    {
      name: 'Robins',
      inventoryImg: 'images/inventory/card/item5.png',
      rarityImg: 'images/inventory/rarity/n.png',
      reviewImg: 'images/inventory/character/archer-review.png',
      animateImg: '/images/char/6.gif',
      img: '/images/char/6.png',
      thumbImg: '/images/char/6.png',
    },
    {
      name: 'Sarah',
      inventoryImg: 'images/inventory/card/item2.png',
      rarityImg: 'images/inventory/rarity/n.png',

      reviewImg: 'images/inventory/character/warrior.png',
      animateImg: '/images/char/4.gif',
      img: '/images/char/4.png',
      thumbImg: '/images/char/4.png',
    },
  ],
  [
    {
      name: 'Evilay',
      inventoryImg: 'images/inventory/card/item6.png',
      rarityImg: 'images/inventory/rarity/r.png',
      reviewImg: 'images/inventory/character/devil-review.png',
      animateImg: '/images/char/5.gif',
      img: '/images/char/5.png',
      thumbImg: '/images/char/5.png',
    },
  ],
  [
    {
      name: 'Goblin',
      inventoryImg: 'images/inventory/card/item3.png',
      rarityImg: 'images/inventory/rarity/sr.png',
      reviewImg: 'images/inventory/character/org.png',
      animateImg: '/images/char/1.gif',
      img: '/images/char/1.png',
      thumbImg: '/images/char/1.png',
    },
  ],
  [
    {
      name: 'Dizo',
      inventoryImg: 'images/inventory/card/item4.png',
      rarityImg: 'images/inventory/rarity/ssr.png',
      reviewImg: 'images/inventory/character/demon-review.png',
      animateImg: '/images/char/3.gif',
      img: '/images/char/3.png',
      thumbImg: '/images/char/3.png',
    },
    {
      name: 'Lucia',
      inventoryImg: 'images/inventory/card/item1.png',
      rarityImg: 'images/inventory/rarity/ssr.png',
      reviewImg: 'images/inventory/character/angel-review.png',
      animateImg: '/images/char/2.gif',
      img: '/images/char/2.png',
      thumbImg: '/images/char/2.png',
    },
  ],
]

export const enemiesConfig: EnemyConfig[] = [
  { name: 'Iron Tail', img: './images/pve/monster/beast.gif' },
  { name: 'Blade Mantis', img: './images/pve/monster/beast-1.gif' },
  { name: 'Putrid Snail', img: './images/pve/monster/beast-2.gif' },
  { name: 'Mad Rat', img: './images/pve/monster/beast-3.gif' },
  { name: 'Hooded Death', img: './images/pve/monster/beast-4.gif' },
  { name: 'Poison Stella', img: './images/pve/monster/beast-5.gif' },
  { name: 'Bully Croc', img: './images/pve/monster/beast-6.gif' },
  { name: 'Fox Beastman', img: './images/pve/monster/beast-7.gif' },
  { name: 'Cow Blower', img: './images/pve/monster/beast-8.gif' },
  { name: 'Baroda Maestro', img: './images/pve/monster/beast-9.gif' },
]

export const upgradeChance = [0, 0, 5, 10, 20, 30, 40, 40]

export default game
