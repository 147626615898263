import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import _ from 'lodash'
import { StakingState, UserData } from '../types'
import {
  fetchUserPoolData,
  fetchGameUserTokenInfo,
  fetchPoolDataWithPoolId,
  fetchPendingReward,
  fetchPendingRewardWithPoolId,
} from './fetchGameUserData'

import fetchPools from './fetchPools'

const initialState: StakingState = {
  userData: {
    userTokenData: {
      angelBalance: '0',
      lpBalance: '0',
      angelAllowance: '0',
      lpAllowance: '0',
    },
    userPoolData: [],
  },
  poolInfo: [],
  isLoading: true,
}

export const gameSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setPendingRewardToZero: (state, action) => {
      state.userData.userPoolData[parseInt(action.payload) - 1].pendingReward = '0'
    },
    resetUserData: (state) => {
      state.userData = {
        userTokenData: {
          angelBalance: '0',
          lpBalance: '0',
          angelAllowance: '0',
          lpAllowance: '0',
        },
        userPoolData: [],
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchGameUserData.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchGameUserData.fulfilled, (state, action) => {
      state.userData.userTokenData = action.payload.userTokenData
      state.userData.userPoolData = action.payload.userPoolData.map((data, index) => {
        return { ...data, pendingReward: action.payload.pendingRewards[index] }
      })
      state.isLoading = false
    })
    builder.addCase(fetchUserTokenData.fulfilled, (state, action) => {
      state.userData.userTokenData = action.payload
    })

    builder.addCase(fetchPoolsData.fulfilled, (state, action) => {
      state.poolInfo = action.payload
    })
    builder.addCase(fetchPoolWithId.fulfilled, (state, action) => {
      state.userData.userPoolData[parseInt(action.payload.poolId) - 1] = {
        ...state.userData.userPoolData[parseInt(action.payload.poolId) - 1],
        ...action.payload.userPoolInfo,
      }

      state.poolInfo[parseInt(action.payload.poolId) - 1].totalStaked = action.payload.totalStaked
    })
    builder.addCase(fetchPendingRewardAllPool.fulfilled, (state, action) => {
      action.payload.forEach((data, index) => {
        state.userData.userPoolData[index].pendingReward = data
      })
    })
    // builder.addCase(fetchPendingReward1Pool.fulfilled, (state, action) => {
    //   state.userData.userPoolData[parseInt(action.payload.poolId) - 1].pendingReward = '0'
    // })
  },
})

// thunk

export const fetchGameUserData = createAsyncThunk<any, string>('staking/fetchGameUserData', async (account) => {
  const fetchGameUserTokenInfoPromise = fetchGameUserTokenInfo(account)
  const fetchGameUserInfoPromise = fetchUserPoolData(account)
  const fetchPendingRewardPromise = fetchPendingReward(account)
  const [userTokenData, userPoolData, pendingRewards] = await Promise.all([
    fetchGameUserTokenInfoPromise,
    fetchGameUserInfoPromise,
    fetchPendingRewardPromise,
  ])

  return { userTokenData, userPoolData, pendingRewards }
})

export const fetchUserTokenData = createAsyncThunk('staking/fetchUserTokenData', async (account: string) => {
  const userTokenData = await fetchGameUserTokenInfo(account)
  return userTokenData
})

export const fetchPoolsData = createAsyncThunk('staking/fetchPools', async () => {
  const pools = await fetchPools()
  return pools
})

export const fetchPoolWithId = createAsyncThunk<any, any>('staking/fetchPoolWithId', async ({ account, poolId }) => {
  const data = await fetchPoolDataWithPoolId(account, poolId)
  return data
})

export const fetchPendingRewardAllPool = createAsyncThunk(
  'staking/fetchPendingRewardAllPool',
  async (account: string) => {
    const pendingRewards = await fetchPendingReward(account)
    return pendingRewards
  },
)

export const fetchPendingReward1Pool = createAsyncThunk<any, any>(
  'staking/fetchPendingRewardAllPool',
  async ({ account, poolId }) => {
    const pendingReward = await fetchPendingRewardWithPoolId(account, poolId)
    return { pendingReward, poolId }
  },
)

// actions
export const { setIsLoading, setPendingRewardToZero, resetUserData } = gameSlice.actions

export default gameSlice.reducer
