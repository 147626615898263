const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },

  cake: {
    symbol: 'CAKE',
    address: {
      56: '0xF61e1c34386ADD959937a99117C1fa3fE7DFf2F7',
      97: '0xF61e1c34386ADD959937a99117C1fa3fE7DFf2F7',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  wbnb: {
    symbol: 'wBNB',
    address: {
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },

  angel: {
    symbol: 'angel',
    address: {
      56: '0x205f93cd558aac99c4609d0511829194b5405533',
      97: '0x6670B256c9246B5B5CD2AfccA3F54a3Da021e765',
    },
    decimals: 18,
  },
  angelBnbLp: {
    symbol: 'Angel-LP',
    address: {
      56: '0xFbb3Fa62F7908B2f41a1A5663E92055a30e7e641',
      97: '0x1FED5E1fCd1724AE8ab09dE584D8773D9f3ca5D3',
    },
    decimals: 18,
  },
}

export default tokens
