import React from 'react'
import PoolInfoCard from './PoolInfoCard'
import { WidgetStaking } from './styled'
// .
export default function AngelStaking({ pools }) {
  return (
    <div className="container">
      <WidgetStaking>
        <div className="box__title">
          <h2>Angel Staking</h2>
          <span>Earn Angel</span>
        </div>

        <div className="box__widget">
          {pools.map((item) => (
            <PoolInfoCard pool={item} />
          ))}
        </div>
      </WidgetStaking>
    </div>
  )
}
