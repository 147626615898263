import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
// import AutoScaleContain from 'components2/Common/AutoScaleContain'
import LoadingFullScreen from 'components2/Common/LoadingFullScreen'
import GlobalModal from 'components2/static/GlobalModal'
import useRefresh from 'hooks/useRefresh'
import useWeb3ReactManager from 'hooks/useWeb3ReactManager'
import React, { useEffect } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { fetchGameUserData, fetchPoolsData, setIsLoading, resetUserData, fetchUserTokenData } from 'state/actions'
import { useIsLoadingSelector } from 'state/hooks'
import styled from 'styled-components'
import Popups from './components/Popups'
import './index.scss'
import Pages from './Pages'

function fakeRequest() {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), 3000))
}

const fmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
}
// This config is required for number formating
BigNumber.config({ FORMAT: fmt, EXPONENTIAL_AT: 1000, DECIMAL_PLACES: 80 })

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released 12
  useEffect(() => {
    console.warn = () => null
  }, [])

  const dispatch = useAppDispatch()
  const { account, chainId } = useWeb3React()
  const { fastRefresh } = useRefresh()

  useWeb3ReactManager()

  useEffect(() => {
    if (account) {
      dispatch(fetchUserTokenData(account))
    }
  }, [fastRefresh])

  useEffect(() => {
    if (account) dispatch(fetchGameUserData(account))
    else dispatch(resetUserData())
    dispatch(fetchPoolsData())
  }, [account])

  const isLoadingData = useIsLoadingSelector()

  useEffect(() => {
    if (chainId) {
      window.localStorage.setItem('chainId', chainId.toString())
    }
  }, [chainId])

  useEffect(() => {
    if (!account)
      fakeRequest().then(() => {
        dispatch(setIsLoading(false))
      })
  }, [account, isLoadingData])

  return (
    <HashRouter>
      <Popups />
      {isLoadingData && <LoadingFullScreen />}
      <FullScreenStyled>
        {/* <AutoScaleContain> */}
        {/* auto scale fixed screen size */}
        <FixedScreenSizeStyled>
          <Switch>
            <Route path="/" exact>
              <Pages />
            </Route>
          </Switch>
          <GlobalModal />
        </FixedScreenSizeStyled>
        {/* </AutoScaleContain> */}
      </FullScreenStyled>
    </HashRouter>
  )
}

const FullScreenStyled = styled.div``

const FixedScreenSizeStyled = styled.div`
  position: relative;
`

export default React.memo(App)
