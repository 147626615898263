import React, { useEffect, useState } from 'react'
import { DoubleRightOutlined } from '@ant-design/icons'
import PoolInfoCard from './PoolInfoCard'
import { WidgetStaking, ButtonStyled } from './styled'

export default function LPStaking({ pools }) {
  const [showAnimal, setShowAnimal] = useState(false)
  const handleShow = () => {
    setShowAnimal(!showAnimal)
  }

  return (
    <div className="container">
      <WidgetStaking>
        <div className="box__title">
          <h2>Angel-BNB LP Staking</h2>
          <span>Earn Angel</span>
        </div>

        <div className="box__widget">
          {pools.map((item) => (
            <PoolInfoCard pool={item} />
          ))}
        </div>
      </WidgetStaking>
    </div>
  )
}
