import {useState} from 'react'
import Home from 'views/Home'


export const pageNames = {
  inventory: 'inventory',
  home: 'home',
  angelspve: 'angelspve',
  market: 'market',
  fight: 'fight',
  egg: 'egg',
  mapgame: 'mapgame',
  chest: 'chest',
}

const pages = {
  home: Home,
}
const defaultPage = Home
const Pages = () => {
  const [currentPage, setCurrentPage] = useState('home')
  const PageToRender = pages[currentPage] || defaultPage

  return <PageToRender setCurrentPage={setCurrentPage} />
}

export default Pages
