import React from 'react'
import styled from 'styled-components'

const PopupConnect = ({onClick, title, children}) => {
  return (
    <PopupConnectStyled>
      <div className="popupConnect__wallet">
        <img className="close-icon" src="/images/close-icon.png" alt="close-icon" onClick={onClick} />
        <div className="popupConnect__wallet--img">
          <p>{title}</p>
        </div>
      </div>
      {children}
    </PopupConnectStyled>
  )
}

export const PopupConnectStyled = styled.div`
  background-color: #fff;
  border-radius: 20px;
  background-size: 100% 100%;
  position: relative;
  width: 460px;

  @media (max-width: 767px) {
    width: 360px;
  }

  .close-icon {
    position: absolute;
    right: 15px;
    top: 18px;
    cursor: pointer;
    width: 28px;
    z-index: 3;

    @media (max-width: 767px) {
      right: 8px;
      top: 4px;
    }
  }

  .popupConnect__wallet--img {
    background: linear-gradient(264.32deg, #2697E9 6.28%, #1D6AFF 47.81%, #532AF8 85.22%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
    p {
      margin: 0;
      font-size: 22px;
      font-weight: 700;
      color: #fff;
      padding: 1rem 0px;
      text-transform: uppercase;
    }
  }
`
export default PopupConnect
