import { createAction } from '@reduxjs/toolkit';

export const fetchingMarket = createAction<boolean | undefined>('marketplace/fetchingMarket');

export const fetchingInventory = createAction<boolean | undefined>('marketplace/fetchingInventory');

export const increaseMarketPage = createAction('marketplace/increaseMarketPage');

export const updateMarketPage = createAction<number>('marketplace/updateMarketPage');

export const updateApproveToken = createAction<boolean | undefined>('marketplace/updateApproveToken');