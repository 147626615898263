import erc20ABI from 'config/abi/erc20.json'
import stakingAbi from 'config/abi/Staking.json'
import { stakingConfig } from 'config/constants/staking'
import tokens from 'config/constants/tokens'
import { getAddress, getStakingAddress } from 'utils/addressHelpers'
import { getStakingContract } from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import { UserPoolData } from '../types'

export const fetchGameUserTokenInfo = async (account: string) => {
  const calls = []
  const angelToken = getAddress(tokens.angel.address)
  const lpToken = getAddress(tokens.angelBnbLp.address)

  calls.push({ address: angelToken, name: 'balanceOf', params: [account] })
  calls.push({ address: lpToken, name: 'balanceOf', params: [account] })
  calls.push({ address: angelToken, name: 'allowance', params: [account, getStakingAddress()] })
  calls.push({ address: lpToken, name: 'allowance', params: [account, getStakingAddress()] })

  try {
    const [angelBalance, lpBalance, angelAllowance, lpAllowance] = await multicall(erc20ABI, calls, {
      requireSuccess: false,
    })

    return {
      angelBalance: angelBalance.toString(),
      lpBalance: lpBalance.toString(),
      angelAllowance: angelAllowance.toString(),
      lpAllowance: lpAllowance.toString(),
    }
  } catch (error) {
    console.log('error fetchGameUserTokenInfo', error)
    return {
      angelBalance: '0',
      lpBalance: '0',
      angelAllowance: '0',
      lpAllowance: '0',
    }
  }
}

export const fetchUserPoolData = async (account: string): Promise<UserPoolData[]> => {
  const calls = []
  for (let i = 1; i <= 6; i++) {
    calls.push({ address: getStakingAddress(), name: 'users', params: [i, account] })
  }

  try {
    const results = await multicall(stakingAbi, calls, { requireSuccess: false })

    return results.map((result) => {
      return {
        amountStaked: result.amount.toString(),
        firstTimeDeposit: result.firstTimeDeposit.toNumber(),
        expectedInterestEndStaking: result.expectedInterestEndStaking.toString(),
        lastTimeClaim: result.lastTimeClaim.toString(),
        debt: result.debt.toString(),
      }
    })
  } catch (error) {
    console.log('error fetchUserPoolData', error)
    return []
  }
}

export const fetchPoolDataWithPoolId = async (account: string, poolId: string) => {
  try {
    const stakingContract = getStakingContract()
    const getUserPoolInfoPromise = stakingContract.users(poolId, account)
    const getPoolInfoPromise = stakingContract.pools(poolId)

    const [userPoolInfo, PoolInfo] = await Promise.all([getUserPoolInfoPromise, getPoolInfoPromise])

    return {
      poolId,
      userPoolInfo: {
        amountStaked: userPoolInfo.amount.toString(),
        firstTimeDeposit: userPoolInfo.firstTimeDeposit.toNumber(),
        expectedInterestEndStaking: userPoolInfo.expectedInterestEndStaking.toString(),
      },
      totalStaked: PoolInfo.amount.toString(),
    }
  } catch (error) {
    console.log('error fetchPoolDataWithPoolId', error)
    return []
  }
}

export const fetchPendingReward = async (account: string) => {
  try {
    const calls = []
    for (let i = 1; i <= 6; i++) {
      calls.push({ address: getStakingAddress(), name: 'pendingInterest', params: [i, account] })
    }

    const results = await multicall(stakingAbi, calls, { requireSuccess: false })
    return results.map((result) => result.toString())
  } catch (error) {
    console.log('error fetchPendingReward', error)
  }
}

export const fetchPendingRewardWithPoolId = async (account: string, poolId: string) => {
  try {
    const stakingContract = getStakingContract()
    return (await stakingContract.pendingInterest(poolId, account)).toString()
  } catch (error) {
    console.log('error fetchPendingRewardWithPoolId', error)
  }
}
