import BigNumber from 'bignumber.js'
import CardValue from 'components/CardValue'
import Connect from 'components2/Common/Connect/index'
import ConnectWallet from 'components2/Common/Popup/ConnectWallet'
import GlobalModal from 'components2/static/GlobalModal'
import { useActiveWeb3React } from 'hooks'
import { useUserDataSelector } from 'state/hooks'
import { WidgetMenu } from './styled'

export default function Menu() {
  const { userTokenData } = useUserDataSelector()
  const handleShowPopupConnect = () => {
    return GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
  }

  return (
    <>
      <WidgetMenu>
        <div className="container">
          <div className="box__main">
            <div className="box__left">
              <div className="logo">
                <img src="./images/logo.png" alt="" />
              </div>

              <ul className="list__menu">
                <li>
                  <a href="/" rel="noopener noreferrer">
                    Staking
                  </a>
                </li>

                <li>
                  <a href="https://app.angelscreed.com/#/" rel="noopener noreferrer" target="_blank">
                    Play game
                  </a>
                </li>
              </ul>
            </div>

            <div className="box__right">
              <div className="box__coin">
                <div className="box__coin__item">
                  <img className="gem-token" src="./images/icon/icon-bnb-agt.png" alt="" />
                  <span>
                    <CardValue
                      value={parseFloat(new BigNumber(userTokenData.lpBalance).div(1e18).toFixed(0)) || 0}
                      decimals={0}
                    ></CardValue>
                  </span>
                  <a
                    href="https://pancakeswap.finance/add/BNB/0x205F93cD558aAc99c4609d0511829194B5405533"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src="./images/homepage/icon-plus.png" alt="" />
                  </a>
                </div>
              </div>

              <div className="box__coin">
                <div className="box__coin__item">
                  <img className="gem-token" src="./images/icon/angels-token.png" alt="" />
                  <span>
                    <CardValue
                      value={parseFloat(new BigNumber(userTokenData.angelBalance).div(1e18).toFixed(0)) || 0}
                      decimals={0}
                    ></CardValue>
                  </span>
                  <a
                    href="https://pancakeswap.finance/swap?outputCurrency=0x205F93cD558aAc99c4609d0511829194B5405533"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src="./images/homepage/icon-plus.png" alt="" />
                  </a>
                </div>
              </div>

              <Connect Wallet={() => handleShowPopupConnect()} />
            </div>
          </div>
        </div>
      </WidgetMenu>
    </>
  )
}
