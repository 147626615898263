import stakingAbi from 'config/abi/Staking.json'
import { getStakingAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import { stakingConfig } from 'config/constants/staking'

const fetchPools = async () => {
  try {
    const calls = []
    for (let i = 1; i <= 6; i++) calls.push({ address: getStakingAddress(), name: 'pools', params: [i] })
    calls.push({ address: getStakingAddress(), name: 'getApyOnAmountArrMultiPool', params: [1, 7] })

    const results = await multicall(stakingAbi, calls, { requireSuccess: false })
    const arrApyOnAmount = results.pop()
    return [
      ...results.map((pool, index) => {
        return {
          poolId: pool.id.toString(),
          totalStaked: pool.amount.toString(),
          endBlock: pool.endBlock.toNumber(),
          maxStakingAmount: pool.limit.toString(),
          maxStakingAmountPerUser: pool.limitPerUser.toString(),
          timeLocking: pool.timeLocking.toString(),
          apyOnAmount: arrApyOnAmount[0][index].map((a) => {
            return {
              amount: a.amount.toString(),
              apy: a.apy.toString(),
            }
          }),
          ...stakingConfig[index],
        }
      }),
    ]
  } catch (error) {
    console.log('error fetchPools', error)
    return [
      {
        totalStaked: '0',
        endBlock: 0,
        maxStakingAmount: '0',
        maxStakingAmountPerUser: '0',
        timeLocking: '0',
      },
    ]
  }
}

export default fetchPools
