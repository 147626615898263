import {createSlice} from '@reduxjs/toolkit'
import _ from 'lodash'
import {EggMarketState} from '../types'
import {fetchEggData} from './fetchEggData'
import {fetchGameUserTokenInfo, fetchAmountEggUser, fetchUserCharacter} from './fetchGameUserData'

const initialState: EggMarketState = {
  activeCharacterId: 0,
  eggs: [
    {
      id: 0,
      angelToBuy: '0',
      remainEgg: '0',
      ratio: {type: '', n: '0', r: '0', sr: '0', ssr: '0'},
    },
    {
      id: 1,
      angelToBuy: '0',
      remainEgg: '0',
      ratio: {type: '', n: '0', r: '0', sr: '0', ssr: '0'},
    },
    {
      id: 2,
      angelToBuy: '0',
      remainEgg: '0',
      ratio: {type: '', n: '0', r: '0', sr: '0', ssr: '0'},
    },
    {
      id: 3,
      angelToBuy: '0',
      remainEgg: '0',
      ratio: {type: '', n: '0', r: '0', sr: '0', ssr: '0'},
    },
    {
      id: 4,
      angelToBuy: '0',
      remainEgg: '0',
      ratio: {type: '', n: '0', r: '0', sr: '0', ssr: '0'},
    },
    {
      id: 5,
      angelToBuy: '0',
      remainEgg: '0',
      ratio: {type: '', n: '0', r: '0', sr: '0', ssr: '0'},
    },
  ],
  userData: {
    balance: '0',
    allowance: '0',
    amountEgg: '0',
    amountChar: [],
    characters: [],
  },
  isLoading: true,
}

export const gameSlice = createSlice({
  name: 'Game',
  initialState,
  reducers: {
    setActiveRoundId: (state, action) => {
      state.activeCharacterId = action.payload
    },

    setEgg: (state, action) => {
      const {eggData} = action.payload

      state.eggs = eggData.map((egg, index) => {
        return {id: index, ...egg}
      })
    },
    setUserData: (state, action) => {
      const {userTokenData, amountEgg, characters} = action.payload
      state.userData.balance = userTokenData.angelBalance
      state.userData.allowance = userTokenData.angelAllowance
      state.userData.amountEgg = amountEgg
      state.userData.characters = characters
      for (let i = 0; i < 4; i++) {
        state.userData.amountChar[i] = characters.reduce((prev, cur) => {
          if (cur.heroRarity === i.toString()) return ++prev
          return prev
        }, 0)
      }

      state.isLoading = false
    },
  },
})

export const fetchGameUserData = (account) => async (dispatch) => {
  const fetchGameUserTokenInfoPromise = fetchGameUserTokenInfo(account)
  const fetchUserCharacterPromise = fetchUserCharacter(account)
  const fetchAmountEggUserPromise = fetchAmountEggUser(account)
  const [userTokenData, amountEgg, characters] = await Promise.all([
    fetchGameUserTokenInfoPromise,
    fetchAmountEggUserPromise,
    fetchUserCharacterPromise,
  ])

  dispatch(setUserData({userTokenData, amountEgg, characters}))
}

export const fetchEgg = () => async (dispatch) => {
  const eggData = await fetchEggData()

  dispatch(setEgg({eggData}))
}

// actions
export const {setUserData, setActiveRoundId, setEgg} = gameSlice.actions

export default gameSlice.reducer
