import styled from 'styled-components'

export const WidgetHome = styled.div`
  /* background: url('../images/homepage/bg-home.png') no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: relative; */
`

export const MainContent = styled.div``

export const Item = styled.div`
  &:first-child {
    position: absolute;
    top: 191px;
    left: 850px;
    z-index: 5;
  }

  &:nth-child(2) {
    position: absolute;
    top: 216px;
    left: 534px;
  }

  &:nth-child(3) {
    position: absolute;
    top: 300px;
    left: 390px;
  }

  &:nth-child(4) {
    position: absolute;
    top: 250px;
    left: 1060px;
    z-index: 2;
  }

  &:nth-child(5) {
    position: absolute;
    top: 190px;
    left: 1090px;
  }
`

export const ControlMenu = styled.div`
  width: auto;
  margin: 0 auto;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 84px;
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);

  &:before {
    content: '';
    background: url('../images/homepage/line.png') no-repeat;
    background-size: contain;
    width: 1920px;
    height: 170px;
    position: absolute;
    top: 24px;
    left: -304px;
  }
`

export const ItemControlMenu = styled.div`
  position: relative;
  z-index: 4;
  cursor: pointer;
  transition: 0.6s ease-in-out;

  &:nth-child(3n) {
    img {
      margin-top: -97px;
    }
  }

  &:hover {
    transform: scale(1.1);
    transition: 0.6s ease-in-out;
  }

  .itemControl__no-drop {
    cursor: no-drop;
  }

  .content {
    span {
      -webkit-text-stroke: 1px #000000;
      color: #ffc165;
      font-size: 1.8em;
      font-weight: 600;
      text-transform: capitalize;
      position: absolute;
      top: 50px;
      left: 38px;
      z-index: 2;
    }
    .temp {
      width: 170px;
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &:nth-child(3n) {
    span {
      color: #ffc165;
      font-size: 2em;
      font-weight: 600;
      text-transform: uppercase;
      position: absolute;
      left: 176px;
      bottom: 70px;
      z-index: 2;
    }
  }
`
export const BoxVideo = styled.div`
  z-index: 1;
  width: 200px;
  height: 200px;


  @media (max-width: 767px) {
    display: none;
  }
`