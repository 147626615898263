import BigNumber from 'bignumber.js'
import CardValue from 'components/CardValue'
import React, { useEffect, useState } from 'react'
import { usePoolSelector } from 'state/hooks'
import { PoolInfo } from 'state/types'
import styled from 'styled-components'
import { WidgetPrice } from './styled'

interface Props {
  tokenPools: PoolInfo[]
  lpPools: PoolInfo[]
}

const angelPrice = 0.005
const lpPrice = 0.005

export default function Menu({ tokenPools, lpPools }: Props) {
  const totalToken = tokenPools.reduce((prev, cur) => {
    return prev.plus(new BigNumber(cur.totalStaked))
  }, new BigNumber(0))

  const totalLp = lpPools.reduce((prev, cur) => {
    return prev.plus(new BigNumber(cur.totalStaked))
  }, new BigNumber(0))

  const totalVolume = totalToken.times(angelPrice).plus(totalLp.plus(lpPrice))

  return (
    <>
      <div className="container">
        <WidgetPrice>
          <div className="item">
            <h3>Angel lock</h3>
            <p>
              {/* <span>$</span> */}
              <CardValue value={parseFloat(totalToken.div(1e18).toFixed(0)) || 0} decimals={0}></CardValue>
            </p>
          </div>

          <div className="item">
            <h3>Angel LP lock</h3>
            <p>
              {/* <span>$</span> */}
              <CardValue value={parseFloat(totalLp.div(1e18).toFixed(0)) || 0} decimals={0}></CardValue>
            </p>
          </div>

          <div className="item">
            <h3>TVL</h3>
            <p>
              <CardValue value={parseFloat(totalVolume.div(1e18).toFixed(0)) || 0} decimals={0} prefix="$"></CardValue>
            </p>
          </div>

          <div className="item">
            <h3>Angel price</h3>
            <p>
              <span>$</span>
              {angelPrice}
            </p>
          </div>
        </WidgetPrice>
      </div>
    </>
  )
}
