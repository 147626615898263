export default {
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },

  staking: {
    56: '0x05F34fEA8315Ad2a5Fe6F04bf9C7149B9588bDd0',
    97: '0x86BC9E1b7F2682B0D990a075BC3e6Aa5720A7C94',
  },

  eggMarket: {
    56: '0x2Af4A1d58E12de09306AAc5Bc108ce03459BE226',
    97: '0x2b7dDCc69A21f6E35bf2a908FF9b3c3474bd7712',
  },
  fakeCharacter: {
    56: '0x2131452CcEA76123c81584cC49A495f5774Ae702',
    97: '0x1B1a7B7D094Cc0bE02B93384D68A93eB71EF0593',
  },

  game: {
    56: '0x0506871B68E5823693E115a5e2175b9A2c4CFB42',
    97: '0x8d20e874d3799e7205Adf0D03eF625Bb4bEE5763',
  },
  character: {
    56: '0xF46b29f3A8Cc3Bee845803C11a56251919A5f6c7',
    97: '0x688f9502EB8eA984E4F7059b2Eb5F29D21e10C7a',
  },
  equipment: {
    56: '0xAdD5a0B24E7F987e8FAB863e86BaB1F583c6DFB6',
    97: '0x7EDD7F5aAe70E8F7795E5101F6f672A9Fb1e3cCB',
  },

  priceOracle: {
    56: '0xFBFb4C4E10EBD70DcE28B8b00FdAD6c8e5De38dc',
    97: '0x29150e28Aee936df7123Ab43e252eeaf3b97347C',
  },

  gameWiki: {
    56: '0x7d88930d354BcB4cBB2F28eD4561b267baf147fA',
    97: '0x82a8cB461e67f171790fC14d48d72a09a38727Dc',
  },

  faucet: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x1b33379A0c8b97331d67fbFA082D85f1A2412911',
  },

  market: {
    56: '',
    97: '0x8b58981cC89ab4d2aeBdE7e18a7750F947d640d0',
  },
  tokenTransferProxy: {
    56: '',
    97: '0xDc4809f1ba57870eAE684d0853299c2A1C9ac824',
  },
  nftsTransferProxy: {
    56: '',
    97: '0x703e87ed9c8618C80b0633a1ccd65f054cdE06a9',
  },
  pool: {
    56: '',
    97: '0xd7817D4b4685b37978049e435364E705DDBFdcc5',
  },

  mysteryBox: {
    56: '',
    97: '0x284649A0502fcEA5093FD905537e9FBB96774CB5',
  },
}
